<template>
  <div class="container_no_auth _bg-default">
    <Nav />
    <div class="child_no_auth _100top">
      <div v-if="survey" class="py-5">
        <div class="my-3">
          <h3>All Survey</h3>
          <v-divider></v-divider>
        </div>
        <v-row>
          <v-col cols="12" md="4" sm="6" v-if="role == 3">
            <v-card
              height="210"
              class="radius-card"
              elevation="0"
              to="/psikolog/create-survey"
            >
              <div
                class="d-flex justify-center align-center"
                style="height: 100%"
              >
                <v-btn fab depressed color="blue lighten-4"
                  ><v-icon size="70" color="blue">mdi-plus</v-icon></v-btn
                >
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
            v-for="item in survey.data"
            :key="item.id"
          >
            <v-card height="210" class="radius-card" elevation="0">
              <v-toolbar dense flat class="pt-2 px-0">
                <div
                  class="d-flex align-center"
                  v-for="user in item.relationships"
                  :key="user.id"
                >
                  <div v-if="user.user">
                    <v-avatar class="mr-2">
                      <img
                        :src="
                          user.user.data.photo_profile
                            ? `${env}/upload/photo_profile/${item.user_id}/${user.user.data.photo_profile}`
                            : `${env}/images/logo.png`
                        "
                        class="img-fit"
                        alt=""
                      />
                    </v-avatar>
                  </div>
                  <h4 v-snip="1">{{ user.user.data.nama_lengkap }}</h4>
                </div>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-title
                class="d-flex justify-center pa-2"
                style="height: 100px"
              >
                <h3 class="text-center" v-snip="2">{{ item.judul }}</h3>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-actions>
                <v-chip
                  class="mx-2 white--text"
                  :color="
                    item.kategori == 'Psikologi Klinis'
                      ? 'a__'
                      : item.kategori == 'Psikologi Pendidikan'
                      ? 'b__'
                      : 'c__'
                  "
                >
                  {{ item.kategori }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  fab
                  depressed
                  dark
                  small
                  content="View Detail"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  :to="`/public/survey/${item.id}/view`"
                >
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </v-card-actions>
              <!-- {{ survey }} -->
            </v-card>
          </v-col>
        </v-row>
        <div
          v-if="!survey.data.length"
          class="d-flex align-center flex-column mt-3"
        >
          <img src="../../assets/img/404.svg" height="100px" alt="404" />
          <h3>Belum ada survey!</h3>
        </div>
      </div>
      <div v-if="loading">
        <v-row>
          <v-col cols="12" md="4" v-for="i in 3" :key="i">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Nav from "../../components/General/Nav.vue";

export default {
  components: { Nav },
  name: "publikSurvey",
  data() {
    return {
      item: null,
      find: "",
      loading: false
    };
  },
  computed: {
    ...mapState({
      survey: state => state.survey.public_survey,
      env: state => state.API_URL,
      role: state => state.role
    })
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      let data = { page: 1, limit: 30, find: this.find };
      this.$store.dispatch("survey/publicSurvey", data).then(() => {
        this.loading = false;
      });
    },
    viewTo(id) {
      this.$router.push(`/ebook/${id}/view`);
    }
  }
};
</script>

<style scoped>
.container_no_auth {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.child_no_auth {
  width: 70%;
}
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
</style>
