var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container_no_auth _bg-default"},[_c('Nav'),_c('div',{staticClass:"child_no_auth _100top"},[(_vm.survey)?_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"my-3"},[_c('h3',[_vm._v("All Survey")]),_c('v-divider')],1),_c('v-row',[(_vm.role == 3)?_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-card',{staticClass:"radius-card",attrs:{"height":"210","elevation":"0","to":"/psikolog/create-survey"}},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"100%"}},[_c('v-btn',{attrs:{"fab":"","depressed":"","color":"blue lighten-4"}},[_c('v-icon',{attrs:{"size":"70","color":"blue"}},[_vm._v("mdi-plus")])],1)],1)])],1):_vm._e(),_vm._l((_vm.survey.data),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-card',{staticClass:"radius-card",attrs:{"height":"210","elevation":"0"}},[_c('v-toolbar',{staticClass:"pt-2 px-0",attrs:{"dense":"","flat":""}},[_vm._l((item.relationships),function(user){return _c('div',{key:user.id,staticClass:"d-flex align-center"},[(user.user)?_c('div',[_c('v-avatar',{staticClass:"mr-2"},[_c('img',{staticClass:"img-fit",attrs:{"src":user.user.data.photo_profile
                          ? (_vm.env + "/upload/photo_profile/" + (item.user_id) + "/" + (user.user.data.photo_profile))
                          : (_vm.env + "/images/logo.png"),"alt":""}})])],1):_vm._e(),_c('h4',{directives:[{name:"snip",rawName:"v-snip",value:(1),expression:"1"}]},[_vm._v(_vm._s(user.user.data.nama_lengkap))])])}),_c('v-spacer')],2),_c('v-card-title',{staticClass:"d-flex justify-center pa-2",staticStyle:{"height":"100px"}},[_c('h3',{directives:[{name:"snip",rawName:"v-snip",value:(2),expression:"2"}],staticClass:"text-center"},[_vm._v(_vm._s(item.judul))])]),_c('v-divider'),_c('v-card-actions',[_c('v-chip',{staticClass:"mx-2 white--text",attrs:{"color":item.kategori == 'Psikologi Klinis'
                    ? 'a__'
                    : item.kategori == 'Psikologi Pendidikan'
                    ? 'b__'
                    : 'c__'}},[_vm._v(" "+_vm._s(item.kategori)+" ")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100
                }),expression:"{\n                  arrow: true,\n                  arrowType: 'round',\n                  animation: 'fade',\n                  theme: 'light',\n                  maxWidth: 100\n                }"}],attrs:{"color":"blue","fab":"","depressed":"","dark":"","small":"","content":"View Detail","to":("/public/survey/" + (item.id) + "/view")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1)],1)],1)})],2),(!_vm.survey.data.length)?_c('div',{staticClass:"d-flex align-center flex-column mt-3"},[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h3',[_vm._v("Belum ada survey!")])]):_vm._e()],1):_vm._e(),(_vm.loading)?_c('div',[_c('v-row',_vm._l((3),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1)}),1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }